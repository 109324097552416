import * as React from "react"
import { Main } from "../components/layout"
import ProviderLogin from "../components/pages/ProviderLogin"

// markup
const ProviderLoginPage = () => {
	return (
		<>
			<Main>
				<ProviderLogin />
			</Main>
		</>
	)
}

export default ProviderLoginPage
